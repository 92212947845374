body#signinsignup{
  background-image: url("./assets/capstonebackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  width:100vw;
  height: 100vh;
}

html{
  background-color: maroon;
}

body#home{
  background-color: maroon;
}

body#signinorsignup{
  background-image: url("./assets/capstonebackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

body#movieIndex{
  background-image: none;
  background-color: maroon;
}

body#movieShow{
  background-image: none;
  background-color: maroon;
  width:100vw;
  height: 100vh;
}

body#aboutUs{
  background-image: none;
  background-color: maroon;
}

body#movieNew{
  background-image: url("./assets/capstonebackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#new-container {
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  min-height: 100vh; /* Take at least the full height of the viewport */
  padding: 20px; /* Add some padding */
}

#formNew {
  width: 100%;
  max-width: 600px; /* Set a max-width for the form */
  background: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background */
  padding: 20px;
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow for depth */
  margin: auto; /* Center the form in the available space */
}

body#edit{
  background-image: url("./assets/capstonebackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/* Sign In/Sign Up Page */
#choice-container{
  top: 100px;
}

.sign-container{
  text-align: center;
  padding: 50px;
}

.sign{
  height: 200px;
  background-color: #FBD4C4;
}

.webtitle{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
}

.bottom-container{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

.ticket{
  height: 300px;
}

.booth{
  height: 400px;
}

.ticket{
  position: relative;
}

#button{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 20px;
  margin-top: 20px;
}

/* Sign Up and Sign In page styling */
#content-container {
  position: relative;
  top: 200px; 
}

#content-container1 {
  position: relative;
  top: 100px; 
} 

#form {
  background: #fbd4c4;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 500px;
  padding: 10px 50px;
}

h1{
  text-align: center;
}

#signupbutton, #movieNewButton{
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

#gobackbutton{
  background: rgb(97, 96, 96);
  color: white;
  margin-bottom: 100px;
}

/* Navbar Header Styling */
.logo{
  height: 90px;
}

#bar{
  background-color: #FBD4C4;
}

#web-title{
  color: black;
  font-size: 30px;
}

.links-header1{
  display: flex;
}

#link {
  text-decoration: none;
  color: black;
  position:relative;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  font-size:20px;
  transition:all 1s;
  padding-top: 12px;
  &:after,&:before{
    content:" ";
    width:10px;
    height:10px;
    position:absolute;
    transition:all 1s;
  }
  &:after{
    top:-1px;
    left:-1px;
    border-top:5px solid black;
    border-left:5px solid black;
  }
  &:before{
    bottom:-1px;
    right:-1px;
    border-bottom:5px solid black;
    border-right:5px solid black;
  }
  &:hover{
    border-top-right-radius:0px;
    border-bottom-left-radius:0px;
    color:black;
    &:before,&:after{
      width:100%;
      height:100%;
      border-color:black;
    }
  }
}

/* .user{
  height: 40px;
} */

.user {
  height: 40px;
  width: 40px; /* Assuming the icon is a square */
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  will-change: transform; /* Optimizes animations */
  transition: transform 0.3s ease;
  display: flex; /* To center the content (if there's any) */
  justify-content: center;
  align-items: center; /* Background color, adjust as needed */
}

.user:hover {
  animation: flipAndBeat 1.4s infinite linear;
}

@keyframes flipAndBeat {
  0%, 100% {
    transform: rotateY(0) scale(1);
  }
  25% {
    transform: rotateY(180deg) scale(1.1);
  }
  50% {
    transform: rotateY(360deg) scale(1);
  }
  75% {
    transform: rotateY(540deg) scale(1.1);
  }
}
/* end of spin icon */

.bg-transparent:hover{
  transform: scale(1.05) translateX(2px);
}

.dropdown-link{
  text-decoration: none;
  color: black;
}


/* Footer styling */
.footer{
  margin: auto;
  text-align: center;
}

#navbarfooter{
  background-color: #FBD4C4;
}

#footerwords{
  margin-bottom: 2px;
  margin-top:10px
}

#aboutuslink{
  text-decoration: none;
  color: black;
  position:relative;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  font-size:17px;
  transition:all 1s;
  top: 4px;
  &:after,&:before{
    content:" ";
    width:10px;
    height:10px;
    position:absolute;
    transition:all 1s;
  }
  &:after{
    top:-1px;
    left:-1px;
    border-top:5px solid black;
    border-left:5px solid black;
  }
  &:before{
    bottom:-1px;
    right:-1px;
    border-bottom:5px solid black;
    border-right:5px solid black;
  }
  &:hover{
    border-top-right-radius:0px;
    border-bottom-left-radius:0px;
    color:black;
    &:before,&:after{
      width:100%;
      height:100%;
      border-color:black;
    }
  }
}

/* Index Page Styling */
.signpages-container{
  position: relative;
  text-align: center;
}

.pagesigns{
  margin-top: 30px;
  height: 100px;
  background-color: #FBD4C4;
}

.signtext{
  position: absolute;
  top: 80px; 
  left: 50%;
  transform: translate(-50%, -50%);
}

#index-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 60px;
  margin-top: 30px;
  overflow: hidden;
}

#index-container2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px; 
  margin: 0 auto;
}

.card-container2{
  flex: 0 0 calc(48% - 10px);
  margin: 10px;
  padding-bottom: 70px;
}

.card-container{
  flex: 0 0 calc(25% - 20%);
  margin: 10px;
  margin-bottom: auto;
  margin-top: auto;
  :hover{
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black"><text y="18" font-size="24">🍿</text></svg>'), auto;
  }
}

#card{
  width: 100%;
  margin-bottom: 30px;
}

#card-title{
  text-align: center;
  font-weight: 700;
}

#body, #rating, .button-container{
  text-align: center;
}

#show-button{
  background-color: transparent;
  cursor: pointer;
}

#show-button:hover, .editdelete-button:hover{
  transform: scale(1.05) translateX(5px);
}

.show-link{
  text-decoration: none;
  color: black;
}



/* Show Page Styling */
.movie-title{
  color: white;
}

.movie-attributes{
  display: flex;
  justify-content: center;
  padding: 10px;
  color: black;
}

.show-container{
  text-align: center;
  padding: 20px;
  color: black;
}

#show-rating{
  margin-right: 50px;
  margin-left: 50px;
  padding-top: 12px;
}

#icon{
  height: 40px;
  width: 40px;
  color: white;
  padding-right: 10px;
}

#show-image{
  height: 300px;
}

.example {
  position: relative;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
}
.content {
  opacity: 0;
  font-size: 15px;
  position: absolute;
  top: 0; 
  width: 100%;
  color: white;
  background-color: rgba(200, 200, 200, 0.5);
  height: 300px;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}
.example .content:hover {
  opacity: 1;
}
.example .content .text {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
}
.example .content:hover .text {
  opacity: 1;
  transform: translateY(250px);
  -webkit-transform: translateY(250px);
}

.movie-genre{
  display: inline-block;
  color: white;
  padding: 3px;
}

.show-description{
  width: 500px;
  margin: auto;
  padding: 10px;
  color: white;
}

.watch{
  display: flex;
  text-decoration: none;
  color: black;
  color: white;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

/* About Us Page Styling */
.film-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 77px;
}

.col1{
  flex: 1;
  text-align: center;
  position: relative;
}

.film1-image {
  position: relative;
}

.movie-film {
  margin-left: 100px;
  margin-right: 100px;
  height: 500px;
  width: 400px;
}

.container1{
  position: absolute;
  bottom: 500px;
  left: 0;
  width: 100%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  bottom: 50px;
  top: 2px;
  bottom: -30px;
}

#tori-card, #roniel-card{
  margin: auto;
  top: -0.3%;
}

#adrian-card, #ilene-card{
  margin: auto;
}

#text{
  font-size: small;
}

#social-container{
  display: flex; 
}

.linkedin, .github{
  font-size: 25px;
  color: black;
  margin-left: 5px;
}

.linkedin:hover, .github:hover{
  color:maroon;
}

/* Not Found Page styling */
#notfound{
  display: flex;
  justify-content: center;
}

/* Movie New Page */
#new-container{
  margin-bottom: 50px;
}

#formNew{
  background: #fbd4c4;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 500px;
  padding: 10px 50px;
  margin-top: 40px;
  margin-bottom: 50px;
}

/* Home Page CSS */
.movie-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px; 
}

.movie {
  flex: 0 0 auto;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  transition: transform 0.3s ease; 
  position: relative; 
  z-index: 1; 
  :hover{
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black"><text y="18" font-size="24">🍿</text></svg>'), auto;
  }
}

.movie:hover {
  transform: scale(1.5);
  z-index: 2;
}

.movie img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.movie:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: -1;
}

/* Scrollbar styles */
.movie-container::-webkit-scrollbar {
  height: 10px;
}

.movie-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.movie-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Search bar  */
.search-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Style the search input */
.search-input {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 20px 0 0 20px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease; 
}

/* Style the search button */
.search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #007BFF;
  color: white;
  text-transform: uppercase;
  border-radius: 0 20px 20px 0; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.search-button:hover {
  background-color: #0056b3;
}

.search-input:focus {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .search-button {
    display: none;
  }
}

/* MovieShow.css */
#movieShow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url('./assets/capstonebackground.png');  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #000000;
  min-height: 100vh;
}

/* css for home page */
.movie-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px; 
  padding: 20px;
  justify-items: center; 
  margin-bottom: 55px;
}
.movie {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%;
  max-width: 200px; 
}
.movie img {
  width: 100%; 
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}
.movie h3 {
  text-align: center;
  font-size: 1rem;
  margin: 0 10px; 
}
/* CSS for home page */

.show-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.movie-image{
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.movie-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  width: 100%;
  margin-left: 20px;
}

.movie-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.trailer-container {
  width: 100%;
  margin-top: 20px;
}

.trailer-container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.movie-rating {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.movie-genre,
.movie-description {
  margin: 5px 0;
}

.movie-trailer-link {
  display: inline-block;
  margin-top: 15px;
  background-color: #e62429;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.movie-trailer-link:hover {
  background-color: #c4121c;
}

.show2-container{
  text-align: center;
  align-items: flex-start; 
  justify-content: space-around; 
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Home Page Styling */
.home-title{
  color: white;
}

/* Edit Page Styling */
#formEdit{
  background: #fbd4c4;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 500px;
  padding: 10px 50px;
  margin-top: 20px;
  margin-bottom: 50px;
}

#ratingedit{
  text-align: left;
}

.editdelete-button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #100f0f; /* Dark background for the cinema effect */
  color: #fff;
  border: 2px solid #fff; /* Add a border to resemble a film */
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  position: relative; /* Required for absolute positioning of the pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element is clipped to the button's border-radius */
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.editdelete-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%; /* Start from the left */
  top: 0;
  background: repeating-linear-gradient(
    90deg,
    #666 0,
    #666 10px,
    #777 10px,
    #777 20px
  ); /* Create a striped pattern to resemble film */
  transition: left 0.4s ease-in-out;
}

.editdelete-button:hover::before {
  left: 100%; /* End to the right */
}

.editdelete-button:hover {
  background-color: #555; /* Darker background when hovering */
  color: #fff;
  border-color: #777; /* Lighten the border on hover */
}

.editdelete-button:active {
  transform: translateY(1px);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
/* index button */

.view-button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #100f0f; /* Dark background for the cinema effect */
  color: #fff;
  border: 2px solid #fff; /* Add a border to resemble a film */
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  position: relative; /* Required for absolute positioning of the pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element is clipped to the button's border-radius */
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.view-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%; /* Start from the left */
  top: 0;
  background: repeating-linear-gradient(
    90deg,
    #666 0,
    #666 10px,
    #777 10px,
    #777 20px
  ); /* Create a striped pattern to resemble film */
  transition: left 0.4s ease-in-out;
}

.view-button:hover::before {
  left: 100%;
}

.view-button:hover {
  background-color: #555; /* Darker background when hovering */
  color: #fff;
  border-color: #777; /* Even darker green on hover */
}

.view-button:active {
  transform: translateY(1px); /* Slightly larger movement on click */
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); /* Reduce shadow when button is pressed */
}
